import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { CloudPage } from '../../components/pages';

const Cloud: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { page: { eq: "cloud" } }) {
        frontmatter {
          primaryColor
          title
          subTitle
          text
          card1Title
          card1Text
          card2Title
          card2Text
          card3Title
          card3Text
          card4Title
          card4Text
        }
      }
      file(relativePath: { eq: "posters/cloud.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 25) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const textData = data.markdownRemark.frontmatter;
  const { fluid } = data.file.childImageSharp;
  const pageData = { ...textData, fluid };
  return <CloudPage data={pageData} />;
};

export default Cloud;
